<template>
  <v-row class="d-flex justify-center mt-0">
    <v-col cols="12" class="d-flex px-sm-8 px-4 pt-0 pb-4 text-h5">
      Información de Áreas de Soporte
    </v-col>
    <v-col cols="12" class="d-flex px-sm-8 px-4">
      <v-row class="d-flex justify-start">
        <v-col
          v-for="(card, index) in cards"
          :key="index"
          cols="12" sm="6" md="4" xl="3"
          class="d-flex justify-center mb-sm-6"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              style="cursor: pointer"
              :width="(hover || card.info) && $vuetify.breakpoint.mdAndUp ? 350 : 300"
              :elevation="(hover || card.info) && $vuetify.breakpoint.mdAndUp ? 12 : 2"
              :class="(hover || card.info) && $vuetify.breakpoint.mdAndUp ? 'my-n4' : ''"
              @click.native="card.info = !card.info"
            >
              <!-- imagen y titulo -->
              <v-img
                :src="`data:image/jpeg;base64,${card.imagen}`"
                :height="(hover || card.info) && $vuetify.breakpoint.mdAndUp ? 233 : 200"
              ></v-img>
              <v-card-title class="d-flex align-start pt-2 pb-0" style="height: 72px; word-break: normal">
                {{ card.nombre }}
              </v-card-title>
              <v-card-actions class="mb-2 mx-4">
                <v-hover v-slot="{ hover }">
                  <v-btn
                    color="primary"
                    block
                  >
                    Información
                    <v-slide-x-transition>
                      <v-icon v-if="hover" right>fas fa-chevron-right</v-icon>
                    </v-slide-x-transition>
                  </v-btn>
                </v-hover>
              </v-card-actions>
              <!-- informacion -->
              <v-expand-transition>
                <v-overlay
                  v-if="card.info"
                  style="z-index: 1"
                  absolute
                >
                  <v-card
                    color="swalBkg"
                    :height="$vuetify.breakpoint.mdAndUp ? 365 : 332"
                  >
                    <v-card-title class="text--primary pb-2" style="word-break: normal">
                      {{ card.nombre }}
                    </v-card-title>
                    <v-card-text class="text--primary" style="height: 73%; overflow-y:auto">
                      <span v-if="card.id == 5" style="white-space: pre-wrap">
                        {{ card.dias }}
                      </span>
                      <template v-else>
                        <p>Horario:</p>
                        {{ card.dias }}
                        <p>Email:</p>
                        {{ card.email }}
                        <p>Teléfono:</p>
                        {{ card.telefono }}
                      </template>
                    </v-card-text>
                  </v-card>
                </v-overlay>
              </v-expand-transition>
            </v-card>
          </v-hover>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data () {
    return {
      cards: []
    }
  },
  async created () {
    if (localStorage.getItem('areas_sop')) return this.cards = JSON.parse(localStorage.getItem('areas_sop'))
    this.$store.state.loading = true
    await this.$store.dispatch('get_info_areas_soporte')
      .then((res) => {
        this.cards = res.data
        localStorage.setItem('areas_sop', JSON.stringify(this.cards))
      })
      .catch(error => {
        this.$store.dispatch('show_snackbar', {
          text: error.message,
          color: 'error'
        })
      })
    this.$store.state.loading = false
  }
}
</script>

<style scoped>
p {
  margin-top: 8px;
  margin-bottom: 0px;
  font-weight: 700;
}
</style>